import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faTwitter,
  faMedium,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./SocialLinks.css";

function SocialLinks({ filter }) {
  const links = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/john-lawniczak-767b511ba/",
      icon: faLinkedin,
    },
    {
      name: "GitHub",
      url: "https://github.com/the-vegetarian-vampire",
      icon: faGithub,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/0xJohnnyLaw",
      icon: faTwitter,
    },
    {
      name: "Medium",
      url: "https://johnlawniczak.medium.com/",
      icon: faMedium,
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/channel/UCFMVgLr81G-opGABi2c8-PA",
      icon: faYoutube,
    },
    // Add other platforms as needed
  ];

  const getColor = (name) => {
    switch (name) {
      case "LinkedIn":
        return "#0a66c2"; // LinkedIn Blue
      case "GitHub":
        return "#333"; // GitHub Black
      case "Twitter":
        return "#1da1f2"; // Twitter Blue
      case "Medium":
        return "#00ab6c"; // Medium Green
      case "YouTube":
        return "#ff0000"; // YouTube Red
      default:
        return "#ffffff"; // Default color (white)
    }
  };

  return (
    <div className="social-links">
      {links.map((link) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className={link.name.toLowerCase()} // this adds a class name based on the platform name
          style={{ color: getColor(link.name) }}
        >
          <FontAwesomeIcon icon={link.icon} />
        </a>
      ))}
    </div>
  );
}

export default SocialLinks;

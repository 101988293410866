import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function CppModal({ isOpen, onClose }) {
  const [copied, setCopied] = useState(false);
  if (!isOpen) {
    return null;
  }

  const codeString = `#include <iostream>
#include <vector>
#include <string>
using namespace std;

// PROTOTYPES [using vectors] pass by reference
void printBoard(const vector<vector<char>> &board);
bool checkWin(const vector<vector<char>> &board, char player);
bool checkDraw(const vector<vector<char>> &board);

int main()
{
    vector<vector<char>> board = {{' ', ' ', ' '},
                                  {' ', ' ', ' '},
                                  {' ', ' ', ' '}};
    string playerX, playerO;
    cout << "Enter name for Player 1: ";
    cin >> playerX;
    cout << "Enter name for Player 2: ";
    cin >> playerO;
    char player = 'X';
    string currentPlayer = playerX;
    while (true)
    {
        printBoard(board);
        int row, col;
        cout << "Player " << currentPlayer << "(" << player << ") ";
        cin >> row >> col;
        if (row < 1 || row > 3 || col < 1 || col > 3)
        {
            cout << "-----> Invalid move." << endl;
            cout << "-----> Try again..." << endl;
            continue;
        }
        if (board[row - 1][col - 1] != ' ')
        {
            cout << "-----> Already taken." << endl;
            cout << "-----> Try again." << endl;
            continue;
        }
        board[row - 1][col - 1] = player;
        if (checkWin(board, player))
        {
            printBoard(board);
            cout << currentPlayer << " wins!" << endl;
            break;
        }
        if (checkDraw(board))
        {
            printBoard(board);
            cout << "It's a draw!" << endl;
            break;
        }
        if (player == 'X')
        {
            player = 'O';
            currentPlayer = playerO;
        }
        else
        {
            player = 'X';
            currentPlayer = playerX;
        }
    }
    return 0;
}

void printBoard(const vector<vector<char>> &board)
{
    cout << "  1 2 3" << endl;
    for (int i = 0; i < 3; ++i)
    {
        cout << i + 1 << " ";
        for (int j = 0; j < 3; ++j)
        {
            cout << board[i][j] << " ";
        }
        cout << endl;
    }
}

bool checkWin(const vector<vector<char>> &board, char player)
{
    for (int i = 0; i < 3; ++i)
    {
        if (board[i][0] == player && board[i][1] == player && board[i][2] == player)
        {
            return true;
        }
    }
    for (int i = 0; i < 3; ++i)
    {
        if (board[0][i] == player && board[1][i] == player && board[2][i] == player)
        {
            return true;
        }
    }
    if (board[0][0] == player && board[1][1] == player && board[2][2] == player)
    {
        return true;
    }
    if (board[0][2] == player && board[1][1] == player && board[2][0] == player)
    {
        return true;
    }
    return false;
}

bool checkDraw(const vector<vector<char>> &board)
{
    for (int i = 0; i < 3; ++i)
    {
        for (int j = 0; j < 3; ++j)
        {
            if (board[i][j] == ' ')
            {
                return false;
            }
        }
    }
    return true;
}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(codeString);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Hide notification after 2 seconds
  };

  return (
    <div className="modal">
      <button onClick={handleCopyToClipboard} className="copy-button">
        Copy
      </button>
      <SyntaxHighlighter language="cpp" style={a11yDark}>
        {codeString}
      </SyntaxHighlighter>
      {copied && (
        <div className="clipboard-notification">Copied to clipboard!</div>
      )}
      <button onClick={onClose} className="button is-link is-small">
        Close
      </button>
    </div>
  );
}

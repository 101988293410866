import React from "react";

const ProjectFaucet = ({
  countdown,
  withdrawError,
  withdrawSuccess,
  getOCTHandler,
  walletAddress,
  transactionData,
}) => {
  return (
    <div>
      <h1 className="faucet-title">John's Faucet</h1>
      <div className="faucet-description">
        <p>
          A blockchain faucet distributes free, small amounts of tokens to
          users, often for utility purposes in a network. This sends 1 "Johnny
          Coin" on{" "}
          <a
            href="https://sepolia.etherscan.io/address/0x3b0fffb39667d3e2202792d50f8eb84dc6e29e52#code"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "'Arial",
              marginLeft: "2px",
              marginRight: "2px",
            }}
          >
            Sepolia Testnet
          </a>{" "}
          after a lockout of 60 seconds.
        </p>
      </div>
      <div className="mt-5">
        {withdrawError && (
          <div className="withdraw-error error-message">{withdrawError}</div>
        )}
        {withdrawSuccess && (
          <div className="withdraw-success">{withdrawSuccess}</div>
        )}
      </div>
      {countdown > 0 && (
        <div className="countdown">Lockout time: {countdown} seconds</div>
      )}
      <div className="box address-box">
        <div className="columns">
          <div className="column is-four-fifths">
            <input
              className="input is-medium"
              type="text"
              placeholder="Enter wallet address (0x...)"
              defaultValue={walletAddress}
            />
          </div>
          <div className="column">
            <button
              className="button is-link is-small"
              onClick={getOCTHandler}
              disabled={walletAddress ? false : true}
            >
              GET TOKENS
            </button>
          </div>
        </div>
        <article className="panel is-grey-darker">
          <p className="panel-heading">Transaction Data</p>
          <div className="panel-block">
            <p className="hash--input">
              {transactionData ? `${transactionData}` : "--"}
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ProjectFaucet;

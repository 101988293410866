import React from "react";

const ProfileDescription = () => {
  return (
    <div className="profile-description">
      <p>
        I'm a Software Engineer based in NYC. With a keen interest in financial
        technologies and decentralized systems, I've developed a range of
        applications that leverage the power of the cloud to deliver secure,
        scalable, and robust services. My unique blend of skills makes me a
        crucial asset to any team looking to strengthen their development.
        <br />
        <br />
        <span
          style={{ display: "block", textAlign: "center", fontStyle: "italic" }}
        >
          ...connect your{" "}
          {/* <img
            src="/pngs/metamask.png"
            alt="Metamask"
            style={{ width: "16px", height: "16px" }}
          />{" "} */}
          wallet...
        </span>
        <br />I built this site using
        <span style={{ color: "#485FC6" }}> React</span>,
        <span style={{ color: "#0a7b07" }}> Ethers</span>,
        <span style={{ color: "#FFD700" }}> Javascript</span>,
        <span style={{ color: "#FF4500" }}> HTML</span> and
        <span style={{ color: "#FF4500" }}> CSS</span>. Additionally, I have
        strong proficiency in...
      </p>
      <br />
      <ul className="skills-list">
        <li className="list-item">
          <span>Low-latency C++ Software</span>
          <img
            width="25px"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg"
            alt="C++"
          />
        </li>
        <li className="list-item">
          <span>Data Structures and Algorithms</span>
          <img width="25px" src="/pngs/connection.png" alt="DS & Algo" />
        </li>
        <li className="list-item">
          <span>Cloud Technology and Infrastructure</span>
          <img width="25px" src="/pngs/cloud-server.png" alt="Cloud" />
        </li>
        <li className="list-item">
          <span>Solidity Smart Contracts</span>
          <img width="25px" src="/pngs/solidity2.png" alt="Solidity" />
        </li>
        <li className="list-item">
          <span>Blockchain Architecture</span>
          <img width="25px" src="/pngs/blockchain2.png" alt="Blockchain" />
        </li>
      </ul>

      <br />
      <div className="project-title project-title-fade-in">Projects</div>
      <div className="down-arrow">
        {" "}
        <img src="/pngs/down-arrow.png" alt="Down arrow" />
      </div>
    </div>
  );
};

export default ProfileDescription;

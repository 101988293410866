import { useState, useEffect } from "react";
import { Contract } from "@ethersproject/contracts";
import { Web3Provider, JsonRpcProvider } from "@ethersproject/providers";

import faucetContract from "../ethereum/faucet";
import priceFeedABI from "../ethereum/priceFeedABI";
import ethfeedABI from "../ethereum/ethfeedABI";
import lotteryABI from "../ethereum/lotABI";
import { fetchSolanaPrice } from "./solanaprice.js";

const WalletHandler = ({
  setConnectWallet,
  setWalletAddress,
  setSigner,
  setFcContract,
  setPriceFeedContract,
  setEthPriceFeedContract,
  setLotteryContract, // Assuming you want to set this in parent component
  setLatestSolPrice,
}) => {
  const ALCHEMY_MAINNET_RPC_URL = process.env.REACT_APP_ALCHEMY_MAINNET_RPC_URL;
  const LOTTERY_CONTRACT_ADDRESS = "0xF5599d7F68604FD91b2a2FB7BD085CdfF000A8d2"; // Place your lottery contract address here

  const [signerState, setSignerState] = useState(null);

  const connectWallet = async () => {
    try {
      const provider = new Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);
      const currentSigner = provider.getSigner();
      console.log("Setting signer:", currentSigner);

      setSigner(currentSigner);
      setSignerState(currentSigner);
      setFcContract(faucetContract(provider));
      setWalletAddress(accounts[0]);

      // Fetch Solana price after successful wallet connection
      const solPrice = await fetchSolanaPrice();
      setLatestSolPrice(solPrice); // Update the Solana price state

      const dataProvider = new JsonRpcProvider(ALCHEMY_MAINNET_RPC_URL);
      const priceFeedContractInstance = new Contract(
        "0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c",
        priceFeedABI,
        dataProvider
      );
      console.log("Price Feed Contract:", priceFeedContractInstance); // <-- Add this line
      setPriceFeedContract(priceFeedContractInstance);

      const ethPriceFeedContractInstance = new Contract(
        "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
        ethfeedABI,
        dataProvider
      );
      console.log("ETH Price Feed Contract:", ethPriceFeedContractInstance); // <-- Add this line

      setEthPriceFeedContract(ethPriceFeedContractInstance);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getCurrentWalletConnected = async () => {
    try {
      const provider = new Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_accounts", []);
      if (accounts.length > 0) {
        const currentSigner = provider.getSigner();
        setSigner(currentSigner);
        setSignerState(currentSigner);
        setFcContract(faucetContract(provider));
        setWalletAddress(accounts[0]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
      });
    }
  };

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
    setConnectWallet(() => connectWallet);
  }, []);

  useEffect(() => {
    if (signerState) {
      const lotteryContractInstance = new Contract(
        LOTTERY_CONTRACT_ADDRESS,
        lotteryABI,
        signerState
      );
      setLotteryContract(lotteryContractInstance);
    }
  }, [signerState]);

  return null;
};

export default WalletHandler;

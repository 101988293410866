import React from "react";

const Audits = () => {
  return (
    <div className="flex-container">
      <h1 className="faucet-title">Audits</h1>

      <p>
        My enthusiasm extends to participating in bug bounties on{" "}
        <a href="https://www.codehawks.com/">CodeHawks</a> where I've
        successfully identified and resolved critical vulnerabilities.
      </p>
      <div className="table-container">
        {" "}
        {/* <- added this */}
        <table>
          <thead>
            <tr>
              <th>Contest</th>
              <th>High</th>
              <th>Medium</th>
              <th>Low</th>
              <th>Gas Optimizations</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="https://www.codehawks.com/report/cljx3b9390009liqwuedkn0m0">
                  Foundry DeFi Stablecoin
                </a>
              </td>
              <td>
                <a href="https://www.codehawks.com/finding/clm81m5r401jrw9ru5q0g5nb9">
                  1
                </a>
              </td>
              <td>
                <a href="https://www.codehawks.com/finding/clm81mcm401kbw9ruzyiqdruz">
                  1
                </a>
              </td>
              <td></td>
              <td>1</td>
              <td>$8.18</td>
            </tr>
            <tr>
              <td>
                <a href="https://www.codehawks.com/report/cljyfxlc40003jq082s0wemya">
                  Escrow Contract
                </a>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>1</td>
              <td>$12.44</td>
            </tr>
            <tr>
              <td>
                <a href="https://www.codehawks.com/report/clkbo1fa20009jr08nyyf9wbx">
                  Beedle
                </a>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <a href="https://www.codehawks.com/finding/cllv3kuf10087w9blci8zkzif">
                  2
                </a>
              </td>
              <td>$28.89</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Audits;

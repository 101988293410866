// PriceFeedHandler.js

import { useEffect } from "react";
import { formatUnits } from "@ethersproject/units";

const PriceFeedHandler = ({
  priceFeedContract,
  ethPriceFeedContract,
  setLatestPrice,
  setLatestEthPrice,
  setIsPriceFeedCalled,
}) => {
  const fetchLatestPrice = async () => {
    if (priceFeedContract) {
      try {
        const rawPrice = await priceFeedContract.latestAnswer();
        console.log("Raw BTC Price:", rawPrice);

        if (rawPrice && rawPrice._isBigNumber) {
          // Extract the hex value and use it
          const adjustedPrice = parseFloat(
            formatUnits(rawPrice._hex, 8)
          ).toFixed(2);
          console.log("Latest BTC Price:", adjustedPrice);
          setLatestPrice(adjustedPrice);
          setIsPriceFeedCalled(true);
        } else {
          console.error("Invalid BigNumber returned:", rawPrice);
        }
      } catch (err) {
        console.error("Failed to fetch BTC price:", err);
      }
    }
  };
  const fetchLatestEthPrice = async () => {
    if (ethPriceFeedContract) {
      try {
        const rawPrice = await ethPriceFeedContract.latestAnswer();
        console.log("Raw ETH Price:", rawPrice);

        // Extract the hex value and use it
        const adjustedPrice = parseFloat(formatUnits(rawPrice._hex, 8)).toFixed(
          2
        );
        console.log("Latest ETH Price:", adjustedPrice);
        setLatestEthPrice(adjustedPrice);
        setIsPriceFeedCalled(true);
      } catch (err) {
        console.error("Failed to fetch ETH price:", err);
      }
    }
  };

  useEffect(() => {
    if (priceFeedContract) {
      fetchLatestPrice();
    }
    if (ethPriceFeedContract) {
      fetchLatestEthPrice();
    }
  }, [priceFeedContract, ethPriceFeedContract]);

  return null; // This component doesn't render anything
};

export default PriceFeedHandler;

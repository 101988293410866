import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const YouTubeLink = () => {
  const youtubeUrl = "https://www.youtube.com/watch?v=eMK6nDefVS0";

  return (
    <a
      href={youtubeUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#ff0000" }} // YouTube Red
    >
      <FontAwesomeIcon
        icon={faYoutube}
        className="icon-grow"
        style={{ fontSize: "20px", marginTop: "10px" }}
      />
    </a>
  );
};

const Owlz = () => {
  return (
    <div>
      <style>
        {`
    .icon-grow:hover {
      transform: scale(1.4);
      transition: transform 0.3s ease-in-out;
    }
    .owl-image {
      cursor: pointer; /* Cursor will be a pointer whenever it's over the image */
    }
    .owl-image:hover {
      transform: scale(1.4);
      transition: transform 0.3s ease-in-out;
    }
  `}
      </style>
      <h1 className="faucet-title">Owlzzzz</h1>
      <img
        src={process.env.PUBLIC_URL + "/owl.gif"}
        alt="Owl GIF"
        className="owl-image"
        width="40"
        height="40"
        style={{ marginTop: "5px" }}
      />
      <div>A social media site.</div>
      <div>
        <YouTubeLink />
      </div>
      {/* <div>
        {" "}
        <img
          src="/coming_soon.gif"
          alt="Coming Soon"
          style={{ width: "100px", height: "auto" }}
        />{" "}
      </div> */}
    </div>
  );
};

export default Owlz;

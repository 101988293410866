import React, { useState, useEffect } from "react";
import { formatEther, parseEther } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";
import ethImage from "../assets/eth.png";

import "./Lottery.css";

import lotABI from "../ethereum/lotABI"; // Path to your ABI

const Lottery = ({ signer }) => {
  const contractAddress = "0xF5599d7F68604FD91b2a2FB7BD085CdfF000A8d2";
  const [winner, setWinner] = useState("");
  const [entranceFee, setEntranceFee] = useState(0);

  const [numPlayers, setNumPlayers] = useState(0);

  console.log(signer);
  const lotteryContract = new Contract(contractAddress, lotABI, signer);

  const enterLottery = async () => {
    console.log("Enter Lottery button clicked!");
    if (!signer) {
      console.error("No signer found!");
      return;
    }

    try {
      const userBalance = await signer.getBalance();

      const tx = await lotteryContract.enterRaffle({
        value: parseEther("0.01"),
      });

      await tx.wait();
      alert("Entered the lottery!");

      // Fetch data again after entering the lottery to get the latest info.
      fetchLotteryData();
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const fetchLotteryData = async () => {
    try {
      const recentWinner = await lotteryContract.getRecentWinner(); // Use the correct function name
      setWinner(recentWinner);

      const numberOfPlayers = await lotteryContract.getNumberOfPlayers(); // Use the correct function name
      setNumPlayers(numberOfPlayers);

      const fee = await lotteryContract.getEntranceFee(); // Use the correct function name
      setEntranceFee(fee.toString());
    } catch (err) {
      console.error("Error fetching lottery data:", err);
    }
  };

  useEffect(() => {
    if (signer) {
      // Check if signer is not null
      fetchLotteryData();
    }
  }, [signer]);

  return (
    <div>
      <h2 className="faucet-title">Decentralized Lottery</h2>
      <p>
        Randomness is hard to achieve in deterministic systems like the
        blockchain. This project requests a random number from an offchain
        oracle, Chainlink to randomly pick a winner.
      </p>
      <span>
        Once started players have <b>30 seconds</b> to enter.{" "}
      </span>

      <div className="box address-box custom-padding">
        {" "}
        {/* <-- Box starts here */}
        <button
          className="button is-link is-small"
          onClick={enterLottery}
          disabled={!signer} // Disable button if no signer
        >
          ENTER LOTTERY
        </button>
        <p>
          <span className="bold-text">Raffle Fee: </span>
          {!signer ? " --" : formatEther(entranceFee)} ETH
        </p>
        <p>
          <a
            href="https://sepolia.etherscan.io/address/0xF5599d7F68604FD91b2a2FB7BD085CdfF000A8d2"
            className="bold-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            Last Winner
          </a>
          <br />
          <span className="smaller-text">{winner}</span>
        </p>
        <p>
          <span className="bold-text">Number of Players: </span>
          {!signer ? " --" : numPlayers.toString()}
        </p>
        {/* Add any additional information you'd like to display */}
      </div>
    </div>
  );
};

export default Lottery;

function Footer() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginBottom: "40px",
      }}
    >
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="/pngs/solidity2.png"
        alt="Solidity"
      />
      <img
        align="left"
        width="32px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/go/go-original-wordmark.svg"
        alt="Go"
      />
      <img
        align="left"
        width="32px"
        style={{ paddingRight: "10px" }}
        src="/pngs/amazon.png"
        alt="Amazon"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-plain.svg"
        alt="C"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg"
        alt="C++"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
        alt="Javascript"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
        alt="Python"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
        alt="React"
      />
      <img
        align="left"
        width="35px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg"
        alt="Docker"
      />
      <img
        align="left"
        width="50px"
        style={{ paddingRight: "10px" }}
        src="/pngs/django.png"
        alt="Django"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain.svg"
        alt="Html"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg"
        alt="CSS"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg"
        alt="Bootstrap"
      />
      <img
        align="left"
        width="25px"
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"
        alt="MySQL"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px", marginLeft: "5px" }}
        src="/pngs/github.png"
        alt="Github"
      />
      <img
        align="left"
        width="30px"
        style={{ paddingRight: "10px" }}
        src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg"
        alt="VSCode"
      />
    </div>
  );
}

export default Footer;

import React from "react";
import SocialLinks from "./SocialLinks";
import "./NavBar.css";

const NavBar = ({
  walletAddress,
  connectWallet,
  disconnectWallet,
  latestPrice,
  latestEthPrice,
  latestSolPrice,
  isPriceFeedCalled,
}) => {
  return (
    <nav className="navbar" style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1 }}>
        <SocialLinks />
      </div>
      <div className="price-display">
        <div className="currency-price">
          {latestPrice ? (
            <div className="currency-container">
              <h2>BTC</h2>
              <p>${latestPrice}</p>
            </div>
          ) : null}
        </div>
        <div className="currency-price">
          {latestEthPrice ? (
            <div className="currency-container">
              <h2>ETH</h2>
              <p>${latestEthPrice}</p>
            </div>
          ) : null}
        </div>
        {/* Add a new section for displaying Solana price */}
        <div className="currency-price">
          {latestSolPrice ? (
            <div className="currency-container">
              <h2>SOL</h2>
              <p>${latestSolPrice}</p>
            </div>
          ) : null}
        </div>

        {isPriceFeedCalled ? (
          <div className="currency-price">
            <div className="currency-container">
              <p className="italic-text">
                Wallet called Chainlink's price feeds
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div style={{ flex: 1 }}>
        <div className="container">
          <div className="navbar-brand"></div>
          <div id="navbarMenu" className="navbar-menu">
            <div className="navbar-end is-align-items-center">
              <button
                className="button is-white connect-wallet"
                onClick={
                  walletAddress && walletAddress.length > 0
                    ? disconnectWallet
                    : connectWallet
                }
              >
                <span>
                  {walletAddress && walletAddress.length > 0
                    ? `Connected: ${walletAddress.substring(
                        0,
                        6
                      )}...${walletAddress.substring(38)}`
                    : "Connect Wallet"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

// Library imports
import { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import { JsonRpcProvider } from "@ethersproject/providers";

import { Web3Provider } from "@ethersproject/providers";

// Ethereum imports
import ethfeedABI from "./ethereum/ethfeedABI";
import faucetContract from "./ethereum/faucet";
import priceFeedABI from "./ethereum/priceFeedABI";

// Component imports
import Footer from "./components/Footer.js";
import NavBar from "./components/NavBar";
import ScrollAnimatedElement from "./Scroll.js";
import WalletHandler from "./components/WalletHandler";
import ProfileDescription from "./components/ProfileDescription";
import ProjectFaucet from "./components/ProjectFaucet";
import Owlz from "./components/Owlz";
import Lottery from "./components/Lottery";
import Audits from "./components/Audits";
import PriceFeedHandler from "./components/PriceFeedHandler";
import CppModal from "./components/CppModal";
import Leetcode from "./components/Leetcode";

import "./components/CppModal.css";

// Styles
import "./App.css";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [fcContract, setFcContract] = useState();
  const [latestPrice, setLatestPrice] = useState("");
  const [latestEthPrice, setLatestEthPrice] = useState("");
  const [priceFeedContract, setPriceFeedContract] = useState(null); // State for BTC price feed contract
  const [ethPriceFeedContract, setEthPriceFeedContract] = useState(null); // State for ETH price feed contract
  const ALCHEMY_MAINNET_RPC_URL = process.env.REACT_APP_ALCHEMY_MAINNET_RPC_URL;
  const [withdrawError, setWithdrawError] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState("");
  const [transactionData, setTransactionData] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [connectWallet, setConnectWallet] = useState(null);
  const [isPriceFeedCalled, setIsPriceFeedCalled] = useState(false);
  const [lotteryContract, setLotteryContract] = useState(null);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [numTicketsBought, setNumTicketsBought] = useState(0);
  const [lotteryPool, setLotteryPool] = useState(0);
  const [signer, setSigner] = useState(null);
  console.log("Signer state in App:", signer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [latestSolPrice, setLatestSolPrice] = useState("");

  useEffect(() => {
    let countdownInterval;
    if (countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(countdownInterval);
    }
    return () => clearInterval(countdownInterval); // cleanup on unmount
  }, [countdown]);

  const getOCTHandler = async () => {
    setWithdrawError("");
    setWithdrawSuccess("");
    try {
      const fcContractWithSigner = fcContract.connect(signer);
      console.log("Contract Address: ", fcContract.address);
      console.log("User Address: ", walletAddress);
      const resp = await fcContractWithSigner.requestJohnnyCoin();
      setWithdrawSuccess("You got Johnny Coin!");
      setTransactionData(resp.hash);
    } catch (err) {
      console.error("Error details:", err);
      setWithdrawError(err.message);
    }
    setCountdown(60);
  };

  const disconnectWallet = () => {
    console.log("disconnectWallet function called");

    setWalletAddress(""); // Reset the wallet address to its initial state
    setSigner(null); // Reset the signer
    setFcContract(null); // Reset the contract instance
  };

  return (
    <div>
      {/* WalletHandler can be integrated here to manage wallet-related state and logic */}
      <WalletHandler
        setConnectWallet={setConnectWallet}
        setWalletAddress={setWalletAddress}
        setSigner={setSigner}
        setFcContract={setFcContract}
        setPriceFeedContract={setPriceFeedContract}
        setEthPriceFeedContract={setEthPriceFeedContract}
        setLatestPrice={setLatestPrice}
        setLatestEthPrice={setLatestEthPrice}
        setLotteryContract={setLotteryContract}
        setTicketPrice={setTicketPrice}
        setNumTicketsBought={setNumTicketsBought}
        setLotteryPool={setLotteryPool}
        setLatestSolPrice={setLatestSolPrice} // Passing the function to update Solana price
      />
      <PriceFeedHandler
        priceFeedContract={priceFeedContract}
        ethPriceFeedContract={ethPriceFeedContract}
        setLatestPrice={setLatestPrice}
        setLatestEthPrice={setLatestEthPrice}
        latestSolPrice={latestSolPrice} // Optional: Pass the latest Solana price
        setIsPriceFeedCalled={setIsPriceFeedCalled}
      />
      <NavBar
        walletAddress={walletAddress}
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
        latestPrice={latestPrice}
        latestEthPrice={latestEthPrice}
        latestSolPrice={latestSolPrice} // Add this line to pass the Solana price
        isPriceFeedCalled={isPriceFeedCalled}
      />

      <section className="hero is-fullheight">
        <div className="faucet-hero-body">
          <div className="container has-text-centered main-content">
            {/* Image and Resume Elements */}
            <div className="profile-container">
              <img src="/main.jpg" alt="Profile" className="profile-image" />
              <div className="name-and-resume">
                <h1 className="profile-name">John Lawniczak</h1>
                <a
                  href="/John Lawniczak - Resume.pdf"
                  download
                  className="download-resume"
                >
                  Download Resume
                </a>
              </div>
            </div>

            <ProfileDescription />
            {/* <ScrollAnimatedElement> */}
            <div className="separator"></div>

            <ProjectFaucet
              countdown={countdown}
              withdrawError={withdrawError}
              withdrawSuccess={withdrawSuccess}
              getOCTHandler={getOCTHandler}
              walletAddress={walletAddress}
              transactionData={transactionData}
            />
            <div className="separator"></div>

            <Owlz />
            <div className="separator"></div>
            <div>
              {" "}
              <Leetcode />
            </div>

            <div className="separator"></div>
            {/* <ScrollAnimatedElement> */}
            <Lottery signer={signer} />
            <div className="separator"></div>
            <Audits />

            <div className="separator"></div>
            {/* <button
              onClick={() => setIsModalOpen(true)}
              className="button is-link is-small"
            >
              Tic Tac Toe (C++)
            </button> */}

            <div className="more-projects">
              For more projects visit my{" "}
              <a href="https://github.com/the-vegetarian-vampire"> Github </a>
              and download my{" "}
              <a href="/John Lawniczak - Resume.pdf" download>
                resume.
              </a>
            </div>
            {/* </ScrollAnimatedElement> */}

            <Footer />
          </div>
        </div>
      </section>
      <CppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default App;

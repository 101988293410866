const COINGECKO_API_URL = "https://api.coingecko.com/api/v3/simple/price";

export const fetchSolanaPrice = async () => {
  try {
    const response = await fetch(
      `${COINGECKO_API_URL}?ids=solana&vs_currencies=usd`
    );
    const data = await response.json();
    return data.solana.usd; // Return the price directly
  } catch (error) {
    console.error("Failed to fetch Solana price:", error);
    return null; // Return null in case of an error
  }
};

import React from "react";
import "./Leetcode.css";

function Leetcode() {
  return (
    <a
      href="https://leetcode.com/johnlawniczak/"
      target="_blank"
      rel="noopener noreferrer"
      className="leetcode-button"
    >
      <img src="/pngs/leetcode.png" alt="LeetCode Button" />
    </a>
  );
}

export default Leetcode;
